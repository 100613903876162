* {
    text-decoration: none;
    /* user-select: none; */
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p, ul, li, nav {
    padding: 0;
    margin: 0;
}

.text_capitalize {
    text-transform: capitalize !important;
}

label {
    font-weight: 600;
}

.list_style_none {
    list-style-type: none;
}

a {
    color: inherit;
    text-decoration: none;
}
  

#brand_name {
    font-weight: 600;
    opacity: 0.9;
}

/* Typography */
.h1 {
    font-weight: 700;
    font-size: 2.5rem;
    letter-spacing: -0.5px;
}


.h2 {
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: -0.25px;
}
.h3 {
    font-weight: 600;
    font-size: 1.75rem;
    letter-spacing: 0px;
}

.h1.line_height, .h2.line_height, .h3.line_height {
    line-height: 2.5rem;
}


.h4 {
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: 0.25px;
}

.h5 {
    font-weight: 500;
    font-size: 1.25rem;
    letter-spacing: 0.25px;
}

.h6 {
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: 0.25px;
}

.h4.line_height, .h5.line_height {
    line-height: 2rem;
}

.text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
}

.text_lg {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: 0px;
}

.text_sm {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0px;
}

.faded_9 {
    opacity: 0.9;
}
.faded_8 {
    opacity: 0.8;
}
.faded_7 {
    opacity: 0.7;
}
.faded_6 {
    opacity: 0.6;
}
.faded_5 {
    opacity: 0.5;
}
